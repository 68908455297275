import React from 'react';
import { BlogPost } from 'views/blog-post';
import { SEO } from 'components/seo';
import { graphql, Script } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const BlogPostTemplate = ({ data, location }) => {
    const blogPost = data.contentfulBlogPost;

    return (
        <Layout location={location} isBlogPage>
            <BlogPost blogPost={blogPost} />
            <Script
                key="twitter"
                src="https://platform.twitter.com/widgets.js"
                charSet="utf-8"
            />
        </Layout>
    );
};

export default BlogPostTemplate;

export const Head = ({ data }) => {
    const blogPost = data.contentfulBlogPost;
    const resolvedTitle = blogPost.title;
    const description = blogPost?.shortDescription?.internal?.content;

    const tags = {
        title: resolvedTitle,
        description,
        ogTitle: resolvedTitle,
        ogImage: blogPost?.ogimage?.url ?? blogPost.image.url,
        ogDescription: description,
    };

    return <SEO tags={tags} translate={false} domainOgImage={false} />;
};

BlogPostTemplate.propTypes = {
    location: object.isRequired,
    data: object.isRequired,
};

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        contentfulBlogPost(slug: { eq: $slug }) {
            id
            slug
            updatedAt
            text {
                raw
                references {
                    ... on ContentfulAsset {
                        contentful_id
                        title
                        description
                        gatsbyImageData(quality: 100)
                        internal {
                            type
                        }
                    }
                    ... on ContentfulCodeSnippet {
                        contentful_id
                        title
                        language
                        code {
                            raw
                        }
                        internal {
                            type
                        }
                    }
                    ... on ContentfulCtaBlogPost {
                        contentful_id
                        headline
                        subheadline
                        subheadlineFormatted {
                            raw
                        }
                        internal {
                            type
                        }
                    }
                    ... on ContentfulCtaBlogPostExtended {
                        contentful_id
                        title
                        headline
                        leadtext {
                            raw
                        }
                        internal {
                            type
                        }
                    }
                    ... on ContentfulNftRealityBanner {
                        contentful_id
                        title
                        h1Part1
                        h1Part2
                        leadText {
                            raw
                        }
                        internal {
                            type
                        }
                    }
                    ... on ContentfulImageLink {
                        contentful_id
                        url
                        internal {
                            type
                        }
                        rel
                        image {
                            description
                            file {
                                fileName
                                url
                            }
                            gatsbyImageData(quality: 100)
                        }
                    }
                    ... on ContentfulIframe {
                        id
                        name
                        contentful_id
                        internal {
                            type
                        }
                        attributes {
                            src
                            srcdoc
                            name
                            sandbox
                            allow
                            allowfullscreen
                            width
                            height
                            referrerpolicy
                            loading
                            title
                            frameborder
                            longdesc
                            marginheight
                            marginwidth
                            scrolling
                            allowpaymentrequest
                            id
                            class
                            style
                            tabindex
                            hidden
                            accesskey
                            contenteditable
                            contextmenu
                            dir
                            draggable
                            dropzone
                            lang
                            spellcheck
                            translate
                            autocapitalize
                            itemprop
                            role
                            slot
                            nonce
                        }
                    }
                    ... on ContentfulInsightsRelatedToTopic {
                        __typename
                        contentful_id
                        title
                        content {
                            raw
                            references {
                                ... on ContentfulReference {
                                    contentful_id
                                }
                                ... on Node {
                                    internal {
                                        type
                                    }
                                }
                                ... on ContentfulRelatedListOfArticles {
                                    list {
                                        title
                                        slug
                                        createdAt
                                        date
                                        metadata {
                                            tags {
                                                name
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        internal {
                            type
                        }
                    }
                }
            }
            multipleAuthors {
                name
                jobTitle
                picture {
                    ... on ContentfulAsset {
                        contentful_id
                        title
                        description
                        gatsbyImageData(width: 200, quality: 100)
                        internal {
                            type
                        }
                    }
                }
            }
            title
            date
            updatedAt
            genre
            shortDescription {
                internal {
                    content
                }
            }
            image {
                gatsbyImageData(width: 808, height: 541, quality: 100)
                title
                description
                url
            }
            ogimage {
                url
            }
            altImageDescription
        }
    }
`;
